<template>
    <div class="home">
        <div class="homeTitle">
            <div></div>
            <div class="titleText">许可证申请</div>
            <div class="des">{{ softName }}</div>
        </div>
        <div class="mes" @click="toApply">
            许可证信息填写
        </div>
        <div class="mes" @click="toApplyList">
            许可证申请列表
        </div>
    </div>
</template>

<script>
import { getUserIdByPhone, getLicensesByPhone } from "@/assets/API/pay.js";
import "amfe-flexible/index";
export default {
    name: "Home",
    data() {
        return {
            softName: '',
            urls: ''
        };
    },
    mounted() {
        this.urls = this.$route.query;
        if (this.urls.pid == undefined) {
            // this.xkShow = false;
            // this.phone = this.urls.phone;
            // this.exhibit = false;
            // this.querys();
        } else {
            if (this.urls.pid == 1) {
                this.softName = "SummitMap";
            } else if (this.urls.pid == 2) {
                this.softName = "SummitSolid";
            } else if (this.urls.pid == 4) {
                this.softName = "SummitLab";
            } else if (this.urls.pid == 5) {
                this.softName = "SummitMapHUBEI";
            } else if (this.urls.pid == 7) {
                this.softName = "SummitSolidSGJ";
            } else if (this.urls.pid == 8) {
                this.softName = "SummitTerra";
            } else if (this.urls.pid == 10) {
                this.softName = "SummitViewPro";
            }
        }
    },
    watch: {

    },
    methods: {
        toApply() {
            this.$router.push({
                name: "HomeM", path: "/", query: this.$route.query
            });
        },
        toApplyList() {
            this.$router.push({ name: "ApplyList", path: "/ApplyList", query: this.$route.query });
        },
    },
};
</script>
<style lang="scss" scoped>
.home {
    background-image: url("../../../assets/image/mobileBg1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .homeTitle {
        padding-top: 135px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
            flex: 1;
        }

        .titleText {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 26.06px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: top;

        }

        .des {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 20.27px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: top;
        }
    }

    .mes {
        height: 106px;
        opacity: 1;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.8);
        margin: 30px 15px;
        text-align: center;
        line-height: 106px;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
    }
}
</style>